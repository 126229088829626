<template>
	<div id="app">
		<photoEdit />
	</div>
</template>

<script>
import photoEdit from './components/photoEdit.vue';

export default {
	name: 'App',
	components: {
		photoEdit,
	},
};
</script>

<style></style>
